import React from 'react';
import "./CTA.css";
import Seuraa from "./Seuraa";
import Contact from "./Contact.js";

export default function CTA() {
    return (
        <div className ="CTA" id = "Seuraa">
            <Seuraa />
            <Contact />
        </div>
    )
}