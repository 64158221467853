import React, { useState } from 'react';
import "./Arvot.css";
import "./Yhteys.css";

export default function Yhteys() {

    const [obfuscate, setObfuscate] = useState(true);

    const obfuscateEmail = (e) => {

        e.preventDefault();
        setObfuscate(false);

    }

    return (
        <div className="Tarina" id="tietoa">
            <div className="Tarina-allekirjoituscontainer">
                <div className={obfuscate ? "yhteys-button" : "Tarina-text"}>
                    <h1>Varkauden Vaaputtajat Ry. Hallitus</h1>
                    <p>Yhteydenotot ensisijaisesti osoitteeseen <span className="obfuscate">varkaudenvaaputtajatry(at)gmail.com</span></p>
                    {obfuscate ? <button onClick={obfuscateEmail}>Katso yhteystiedot</button>
                        : <div className="obfuscate text-area">
                            <h3>Puheenjohtaja:</h3>
                            <p>Erkki Loponen</p>
                            <p>050-4547897</p>
                            <p>e.loponen@gmail.com</p>
                            <h3>Varapuheenjohtaja:</h3>
                            <p>Reijo Airaksinen </p>
                            <p>0400-574587</p>
                            <p>reijo.airaksinen(at)varkaus.fi</p>
                            <h3>Sihteeri:</h3>
                            <p>Timo Venäläinen</p>
                            <p>040-5723280</p>
                            <p>timo.venalainen(at)shi-g.com</p>
                            <h3>Rahastonhoitaja:</h3>
                            <p>Arto Reinikainen</p>
                            <p>0400-720447</p>
                            <p>arto.reinikainen(at)kalapaja.com</p>
                            <h3>Hallituksen jäsen</h3>
                            <p>Jukka Halonen</p>
                            <p>050-5869857</p>
                            <p>jukkahalonen58(at)gmail.com</p>
                            <h3>Hallituksen jäsen</h3>
                            <p>Jaakko Putkonen</p>
                            <p>050-3550704</p>
                            <p>sakari.jaakko(at)outlook.com</p>
                            <h3>Hallituksen jäsen</h3>
                            <p>Juha Luostarinen</p>
                            <p>0400-454143</p>
                            <p>eikka.juha.66@gmail.com</p>
                            <h3>Majan isäntä:</h3>
                            <p>Juha Luostarinen</p>
                            <p>0400-454143</p>
                            <p>eikka.juha.66@gmail.com</p>
                            <h3>Koukkulan vuokraus:</h3>
                            <p>Arto Reinikainen</p>
                            <p>0400-720447</p>
                            <p>arto.reinikainen(at)kalapaja.com</p>
                        </div>
                    }

                </div>
            </div>
        </div>
    );

}