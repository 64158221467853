import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faInstagram, faLinkedin, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faAngleRight, faAt, faBars, faCheck, faFish, faHandshake, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import Arvot from "./App_modules/Arvot";
import Banner from "./App_modules/Banner";
import CTA from "./App_modules/CTA/CTA";
import ContactMaja from "./App_modules/ContactMaja";
import Footer from "./App_modules/Footer";
import Lupaus from "./App_modules/Lupaus";
import Menu from './App_modules/Menu';
import Pirtinvirta from "./App_modules/Pirtinvirta";
import ScrollToTop from "./App_modules/ScrollToTop.js";
import Vuokraa from "./App_modules/Vuokraa";
import Yhteys from "./App_modules/Yhteys";

library.add(faTwitter, faFacebook, faInstagram, faWhatsapp, faLinkedin, faHandshake, faBars, faFish, faCheck, faPhone, faAt, faAngleRight, faHome);


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      width: 0,
      height: 0
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }


  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const { error } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <div className="App">
          <Router>
            <ScrollToTop>
              <Menu width={this.state.width} />
              <Switch>
                <Route exact path="/">
                  <Banner height={this.state.height} />
                  <Arvot width={this.state.width} />
                  <Lupaus />
                  <Pirtinvirta />
                  <CTA />
                </Route>
                <Route path="/varaa">
                  <div className="vuokraa-container">
                    <Vuokraa width={this.state.width} />
                    <ContactMaja />
                  </div>
                </Route>
                <Route path="/hallitus">
                  <Yhteys />
                </Route>
              </Switch>
              <Footer />
            </ScrollToTop>
          </Router>
        </div>
      )
    }
  }
}
export default App;
