import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { PUBLIC_IMG_URL } from '../constants';
import "./Menu.css";

export default class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false
        }
        this.showMenu = this.showMenu.bind(this);
    }

    showMenu = () => {
        const { showMenu } = this.state;

        if (showMenu) {
            this.setState({
                showMenu: false
            })
        } else {
            this.setState({
                showMenu: true
            })
        }
    }

    render() {

        if (this.props.width > 768) {
            return (
                <div className="Menu1">
                    <li><a className="Menu-home" href="/"><img className="logoimg_menu" src={PUBLIC_IMG_URL + "/logo.png"} alt="logo" /><p>Varkauden Vaaputtajat ry.</p></a></li>
                    <ul className="Menu-links">
                        <li><a className="Menu-a" href="/">Etusivu</a></li>
                        <li><a className="Menu-a" href="/#tietoa">Tietoa</a></li>
                        <li><a className="Menu-a" href="/#Pirtinvirta">Pirtinvirta</a></li>
                        <li><Link className="Menu-a" to="/varaa#kalamaja">Kalamaja</Link></li>
                        <li><Link className="Menu-a" to="/hallitus">Yhteystiedot</Link></li>
                    </ul>
                </div>
            )
        } else {
            return (
                <div className="Menu2">
                    <div onClick={this.showMenu} className="Menuicon-container">
                        <FontAwesomeIcon className="menubars" icon="bars" />
                        <p style={{ color: "white" }}>Varkauden Vaaputtajat ry.</p>
                        <a className="Menu-home" href="/"><img className="logoimg_menu" src={PUBLIC_IMG_URL + "/logo.png"} alt="logo" /></a>
                    </div >
                    <div style={{ display: this.state.showMenu ? 'block' : 'none', transition: "0.5s" }} className="Menu-links">
                        <li><a className="Menu-a" href="/">Etusivu</a></li>
                        <li><a className="Menu-a" href="/#tietoa">Tietoa</a></li>
                        <li><a className="Menu-a" href="/#Pirtinvirta">Pirtinvirta</a></li>
                        <li><Link className="Menu-a" to="/varaa#kalamaja">Kalamaja</Link></li>
                        <li><Link className="Menu-a" to="/hallitus">Yhteystiedot</Link></li>
                    </div >
                </div >
            )
        }
    }
}