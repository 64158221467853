import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { PUBLIC_IMG_URL } from '../constants';
import "./Footer.css";

export default function Footer() {
    return (
        <Router>
            <div className="Footer">
                <p>Varkauden Vaaputtajat ry.</p>
                <a className="Menu-home" href="/"><img className="logoimg_menu" src={PUBLIC_IMG_URL + "/logo.png"} alt="logo" /></a>
            </div>
        </Router>
    )
}