import React from 'react';
import {BrowserRouter as Router, Link} from 'react-router-dom'
import "./Contact.css";

export default function Contact(){
    return(
        <div className ="Contact">
            <div className ="Contact-container">
                <h3 className = "Contact-title">Majavuokrausta Tyyskänniemestä!</h3>
            </div>
            <div className = "Contact-container">
                Vuokraan sisältyy:
                <ul className="Majalista">
                    <li> Lämmin maja sähkölämmityksellä</li>
                    <li> Takka oleskelutilassa</li>
                    <li> Kylmäkaapit eväille ja juomille</li>
                    <li> Mikroaaltouuni</li>
                    <li> Sähköliesi</li>
                    <li> Saunatilat, puukiuas ja vesipata</li>
                    <li> Grillikatos pihassa</li>
                    <li> Grillikota saaressa</li>
                    <li> Polttopuut</li>
                    <li> Kemiallinen ulko-WC</li>
                </ul>
                <h3>Maja vuokrattavissa 16.4-14.12. välisenä aikana</h3>
                <h2>Majasta voit lukea lisää täältä:</h2>
                <Link className="Button-maja" to="/varaa"><div>Vuokraa maja</div></Link>
            </div>
        </div>
    )
}