import React from 'react';
import "./Seuraa.css";

export default function Seuraa() {
    return (
        <div className="Seuraa" >
            <h1 id="luvat">Pirtinvirran kalastusluvat</h1>
            <div className="Profile-text">
                <h3>6 tuntia - 15 euroa</h3>
                <p><em>Saalisrajoitus: 1 lohikala</em></p>
                <h3>1 vuorokausi - 20 euroa</h3>
                <h3>Vuosilupa - 120 euroa</h3>
                <h3>Yrityslupa - 200 euroa</h3>
                <p><em>Saalisrajoitus: 3 lohikalaa / vrk</em></p>
            </div>
            <h3 style={{ textDecoration: "underline" }}>Kuhan alamittasuositus 50 cm</h3>
            <h2>Luvanmyyntipisteet:</h2>
            <h3>ABC Varkaus</h3>
            <h3 >Minnan Vapa ja Viehe</h3>
            <h3>Varkauden kalapaja</h3>
            <div className="valiviiva"></div>
            <h2>Kalastusluvan lisäksi 18-64-vuotiaiden tulee maksaa valtion kalastushoitomaksu</h2>
            <h3>Valtion kalastushoitomaksun voi ostaa</h3>
            <a href="http://eraluvat.fi"><h3>Eraluvat.fi</h3></a>
            <h3>R-kioskeista</h3>
            <div className="valiviiva"></div>
            <h1>Venevuokraus </h1>
            <div className="Profile-text">
                <h3>6 h - 10 euroa</h3>
                <h3>1 vuorokausi - 15 euroa</h3>
            </div>
            <h2>Vuokrauspiste:</h2>
            <h3>ABC Varkaus</h3>
        </div>
    )
}