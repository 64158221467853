import React, { useState } from 'react';
import { PUBLIC_IMG_URL } from '../constants';
import "./CTA/Contact.css";
import "./ContactMaja.css";

export default function Contact() {

    const [obfuscate, setObfuscate] = useState(true);

    const obfuscateEmail = (e) => {

        e.preventDefault();
        setObfuscate(false);

    }

    return (
        <div className="varaamaja">
            <div className="imagecontainer">
                <h2>Koukkulan maja:</h2>
                <img src={PUBLIC_IMG_URL + "/Koukkula.jpg"} alt="Koukkula" />
                <img src={PUBLIC_IMG_URL + "/koukkula2.jpg"} alt="Koukkula" />
                <img src={PUBLIC_IMG_URL + "/koukkula3.jpg"} alt="Koukkula" />
            </div>
            <div className="Contact">
                <div className="Contact-container">
                    <h1 className="Contact-title">Majavuokrausta Tyyskänniemestä!</h1>
                </div>
                <div className="Contact-container">
                    Vuokraan sisältyy:
                    <ul className="Majalista">
                        <li> Lämmin maja sähkölämmityksellä</li>
                        <li> Takka oleskelutilassa</li>
                        <li> Kylmäkaapit eväille ja juomille</li>
                        <li> Mikroaaltouuni</li>
                        <li> Sähköliesi</li>
                        <li> Saunatilat, puukiuas ja vesipata</li>
                        <li> Grillikatos pihassa</li>
                        <li> Grillikota saaressa</li>
                        <li> Polttopuut</li>
                        <li> Kemiallinen ulko-WC</li>
                    </ul>
                </div>
                <div className="Contact-container">
                    <h2>Hinta:</h2>
                    <h3>100 euroa/kerta</h3>
                </div>
                <div className="Contact-container">
                    <h3>Varataksesi majan ota yhteyttä:</h3>
                    {obfuscate ? <button onClick={obfuscateEmail}>Katso yhteystiedot</button>
                        : <>
                            <p>Arto Reinikainen</p>
                            <p>0400-720447</p>
                            <p>arto.reinikainen(at)kalapaja.com</p>
                        </>
                    }
                </div>
                <h3 style={{ marginTop: "3rem" }}>Majan osoite Google Mapsissa:</h3>
                <a className="Button-maja" href="https://www.google.fi/maps/place/62%C2%B018'31.8%22N+27%C2%B053'36.6%22E/@62.3088366,27.8929608,134m/data=!3m2!1e3!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d62.3088357!4d27.8935085" target="_blank" rel="noopener noreferrer"><div>Majan osoite</div></a>
            </div>
        </div>
    )
}