import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { PUBLIC_IMG_URL } from '../constants';
import "./Arvot.css";

export default class Arvot extends React.Component {
    render() {

        if (this.props.width < 768) {
            return (
                <div className="Tarina" id="tietoa">
                    <div className="Tarina-allekirjoituscontainer">
                        <div className="Tarina-text">
                            Varkauden Vaaputtajat ry on v. 1988 perustettu vetouisteluseura. Yhdistyksen toimintaan kuuluu myös muukin urheilukalastus, kuten heitto- ja perhokalastus sekä talvella pilkintä. Seurassa on noin 100 jäsentä.<br /><br />

                            Yhdistyksen toimintaan kuuluu mm. jäsenistölle järjestettävät useat vetouistelu- ja pilkkikilpailut sekä yhteiset kalastusretket lähiseudun järville. Yhdistys on myös mukana järjestämässä suosittua Vekara Varkauden kalastuskoulua alkukesästä. <br /><br />

                            Varkauden Vaaputtajien tukikohta Koukkula tontteineen sijaitsee Tyyskänniemessä, Navitas taitotalon takana. Jäsenet voivat vapaasti käyttää tiloja omiin kokoontumisiinsa ja saunomiseen. Mökkiä voi vuokrata myös muut kuin jäsenet. Vaaputtajilla on Pirtinvirralla myös jäsenten vapaaseen käyttöön soutuveneitä. Jos haluat liittyä jäseneksi, klikkaa allaolevaa nappia ja ota meidän hallitukseen yhteyttä!<br /><br />

                        </div>
                        <Link className="Button-teemat" href="/hallitus" to="/hallitus">
                            <p>Ota yhteyttä!
                            </p>
                            <FontAwesomeIcon className="Buttonteema-icon" icon={["fas", "angle-right"]} />
                        </Link>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="Tarina" id="tietoa">
                    <div className="Tarina-textcontainer">
                        <div className="Tarina-img">
                            <img src={PUBLIC_IMG_URL + "/pirtinvirta3.jpg"} alt="Pirtinvirta" />
                        </div>
                        <div className="Tarina-allekirjoituscontainer">
                            <div className="Tarina-text">
                                Varkauden Vaaputtajat ry on v. 1988 perustettu vetouisteluseura. Yhdistyksen toimintaan kuuluu myös muukin urheilukalastus, kuten heitto- ja perhokalastus sekä talvella pilkintä. Seurassa on noin 100 jäsentä.<br /><br />

                                Yhdistyksen toimintaan kuuluu mm. jäsenistölle järjestettävät useat vetouistelu- ja pilkkikilpailut sekä yhteiset kalastusretket lähiseudun järville. Yhdistys on myös mukana järjestämässä suosittua Vekara Varkauden kalastuskoulua alkukesästä. <br /><br />

                                Varkauden Vaaputtajien tukikohta Koukkula tontteineen sijaitsee Tyyskänniemessä, Navitas taitotalon takana. Jäsenet voivat vapaasti käyttää tiloja omiin kokoontumisiinsa ja saunomiseen. Mökkiä voi vuokrata myös muut kuin jäsenet. Vaaputtajilla on Pirtinvirralla myös jäsenten vapaaseen käyttöön soutuveneitä. Jos haluat liittyä jäseneksi, klikkaa allaolevaa nappia ja ota meidän hallitukseen yhteyttä!<br /><br />
                            </div>
                            <Link className="Button-teemat" href="/hallitus" to="/hallitus">
                                <p>Ota yhteyttä!
                                </p>
                                <FontAwesomeIcon className="Buttonteema-icon" icon={["fas", "angle-right"]} />
                            </Link>
                        </div>
                    </div>
                </div>
            );
        }
    }
}