import React from 'react';
import { PUBLIC_IMG_URL } from '../constants';
import "./Vuokraa.css";

export default class Vuokraa extends React.Component {
    render() {

        if (this.props.width < 768) {
            return (
                <div className="Tarina" id="Tarina">
                    <div className="Tarina-allekirjoituscontainer">
                        <h1 id="kalamaja">Koukkula</h1>
                        <div className="Tarina-text">
                            Varkauden Vaaputtajilla on oma tukikohta Koukkula tontteineen Tyyskänniemessä Navitas taitotalon takana. Jäsenet voivat vapaasti käyttää tiloja omiin kokoontumisiinsa ja saunomiseen. Jäsenten käytössä on tuparakennus, jossa on oleskelutilat ja saunaosasto. Majalla on käytettävissä mikroaaltouuni, liesi ja kahvinkeitin ruuan valmistukseen. Piha-alueella on grillaus ja savustus mahdollisuus grillikatoksessa ja saaressa olevalla grillikodalla, johon pääsee siltaa myöten. Piharakennuksessa on tarvike ja puuvarastot ja kemiallinen WC. Yhdistys vuokraa Koukkulan tiloja myös ulkopuolisille kokous- ja saunailtoja varten.
                        </div>
                        <h3>Maja vuokrattavissa 16.4-14.12. välisenä aikana.</h3>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="Tarina" id="Tarina">
                    <div className="Tarina-textcontainer">
                        <div className="Tarina-img">
                            <img src={PUBLIC_IMG_URL + "/koukkula4.jpg"} alt="Pirtinvirta" />
                        </div>
                        <div className="Tarina-allekirjoituscontainer">
                            <h1 id="kalamaja">Koukkula</h1>
                            <div className="Tarina-text">
                                Varkauden Vaaputtajilla on oma tukikohta Koukkula tontteineen Tyyskänniemessä Navitas taitotalon takana. Jäsenet voivat vapaasti käyttää tiloja omiin kokoontumisiinsa ja saunomiseen. Jäsenten käytössä on tuparakennus, jossa on oleskelutilat ja saunaosasto. Majalla on käytettävissä mikroaaltouuni, liesi ja kahvinkeitin ruuan valmistukseen. Piha-alueella on grillaus ja savustus mahdollisuus grillikatoksessa ja saaressa olevalla grillikodalla, johon pääsee siltaa myöten. Piharakennuksessa on tarvike ja puuvarastot ja kemiallinen WC. Yhdistys vuokraa Koukkulan tiloja myös ulkopuolisille kokous- ja saunailtoja varten.
                            </div>
                            <h3>Maja vuokrattavissa 16.4-14.12. välisenä aikana.</h3>
                        </div>
                    </div>
                </div>
            );
        }
    }
}