import React from 'react';
import "./Lupaus.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Arvot() {
    return (
        <div className = "Lupaus" id="Teemat">
            <div className="Lupaus-blockcontainer">
                <div className="Lupaus-block" >
                    <div className="Lupaus-icon">
                        <FontAwesomeIcon icon ="home"/>
                    </div>
                    <div>
                        <h2>Maja Tyyskänniemessä</h2>
                        <p>Yhdistyksen oma tukikohta Koukkula tontteineen sijaitsee Tyyskänniemessä Navitas-taitotalon takana. Jäsenet voivat vapaasti käyttää tiloja omiin kokoontumisiinsa ja saunomiseen. Mökkiä voi vuokrata myös muut kuin jäsenet. Jäsenten käytössä on tuparakennus, jossa on oleskelutilat ja saunaosasto. Majalla on käytettävissä mikroaaltouuni, liesi ja kahvinkeitin ruuan valmistukseen. Piha-alueella on grillaus- ja savustusmahdollisuus grillikatoksessa ja saaressa olevalla grillikodalla, johon pääsee siltaa myöten. Piharakennuksessa on tarvike- ja puuvarastot sekä kemiallinen WC. Yhdistys vuokraa Koukkulan tiloja myös ulkopuolisille kokous- ja saunailtoja varten. Maja on vuokrattavissa 16.4-14.12. välisenä aikana.</p>
                    </div>
                </div>
                <div className="Lupaus-block">
                    <div className="Lupaus-icon">
                        <FontAwesomeIcon icon= "handshake"/>
                    </div>
                    <div>
                        <h2>Monenlaista toimintaa</h2>
                        <p>Yhdistyksen toimintasuunnitelmaan kuuluu mm. jäsenistölle järjestettävät useat vetouistelu- ja pilkkikilpailut. Yhdistys järjestää yhteisiä kalastusretkiä lähiseudun järville ja vuokraa tarvittaessa retkiä varten tukikohdan. Vekara Varkaus -tapahtuman kalastuskoulu kuuluu myös alkukesän ohjelmaan, sekä koululaisten kalastuspäivien järjestelyissä Vaaputtajat ovat mukana. Yhdistyksen vuosikokous vahvisti seuraan liittymismaksuksi aikuiselta 350 e ja alle 18- vuotiailta 35 e. Aikuisen liittymismaksun jälkeen ei muilta perheenjäseniltä peritä liittymismaksua. Vuosittaiset jäsenmaksut ovat 25 e varsinaisilta jäseniltä, 15 e aikuisilta perheen jäseniltä ja 10 e alle 18- vuotiailta.</p>
                    </div>
                </div>
                <div className="Lupaus-block">
                    <div className="Lupaus-icon">
                        <FontAwesomeIcon icon= "fish"/>
                    </div>
                    <div>
                        <h2>Kalastusluvat</h2>
                        <p>Pirtinvirralla on kalastusta varten soutuveneitä jäsenistön vapaassa käytössä. Kuka tahansa voi ostaa kalastusluvat Pirtinvirralle, valtion kalastushoitomaksun lisäksi tulee maksaa Pirtinvirran alueen oma kalastuslupa. Jäsenet saavat 50% alennuksen Pirtinvirran Urheilukalastusalueen kalaluvista. Yhdistys hoitaa, valvoo ja suorittaa kalanistutukset Pirtinvirralla. Kaikki lupatulot käytetään kalanistutukseen, pääasiallisesti kirjoloheen. Kalastuslupia myy Minnan Vapa ja Viehe, Varkauden Kalapaja ja ABC-huoltamo. Virralla on myös soutuveneitä vuokrattavana, vuokraus on ABC-huoltamolla.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}