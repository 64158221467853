import React from 'react';
import { PUBLIC_IMG_URL } from '../constants';
import "./Banner.css";


class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            banner: this.props.banner
        }
    }

    render() {
        if (this.state.banner === "Vuokraa") {
            return (
                <header className="head" id="top">
                    <div className="App-header">
                        <div className="Banner-slogan">
                            <div className="Banner-slogan-text">
                                <img className="logoimg" src={PUBLIC_IMG_URL + "/logo.png"} style={{ animation: "none", opacity: "1" }} alt="logo" />
                                <h1 className="Banner-slogan__name" style={{ animation: "none", opacity: "1" }}>Vuokraa maja</h1>
                            </div>
                        </div>
                    </div>
                </header>
            )
        } else if (this.state.banner === "Yhteys") {

            return (
                <header className="head" id="top">
                    <div className="App-header">
                        <div className="Banner-slogan">
                            <div className="Banner-slogan-text">
                                <img className="logoimg" src={PUBLIC_IMG_URL + "/logo.png"} style={{ animation: "none", opacity: "1" }} alt="logo" />
                                <h1 className="Banner-slogan__name" style={{ animation: "none", opacity: "1" }}>Yhteystiedot</h1>
                            </div>
                        </div>
                    </div>
                </header>
            )
        } else {
            return (
                <header className="head" id="top">
                    <div className="App-header">
                        <div className="Banner-slogan">
                            <div className="Banner-slogan-text">
                                <img className="logoimg" src={PUBLIC_IMG_URL + "/logo.png"} alt="logo" />
                                <h1 className="Banner-slogan__name">Varkauden Vaaputtajat ry.</h1>
                                <h1 className="Banner-slogan__slogan">Urheilukalastusseura Varkaudessa</h1>
                                {/*<div className = "Banner-slogan__secondarycolor"><h1 className="Banner-slogan__slogan">unelma?</h1></div>*/}
                            </div>
                        </div>
                    </div>
                </header>
            )
        }
    }
}
export default Banner;