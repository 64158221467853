import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { PUBLIC_IMG_URL } from '../constants';
import "./Pirtinvirta.css";

export default class Pirtinvirta extends React.Component {
    render() {
        return (
            <div className="Tarina" id="Pirtinvirta">
                <div className="Pirtinvirta-allekirjoituscontainer">
                    <div className="Pirtinvirta-textcontainer">
                        <h1>Pirtinvirran alue</h1>
                        <p className="Pirtinvirta-text">
                            Pirtinvirta sijaitsee Varkauden keskustassa palvelujen äärellä. Virran muodostaa n. 200 metrin puistoalue, jonka rannoilla on hyvät heittolaiturit. Jokiosuudella voit kalastaa myös soutuveneestä käsin.<br /><br />

                            Alueella esiintyy luontaisesti taimenta, järvilohta, kuhaa, haukea, ahventa ym. tavanomaisia kaloja, jopa ankeriaita. Virtaan istutetaan vuosittain n. 1000 kg pyyntikokoista kirjolohta, joukkoon laitetaan myös muutamia isompia ”tärppikaloja”. Istutuksia tehdään huhti-lokakuun aikana tasaisin välein n. 200 kg:n erissä. <br /><br />

                            Pirtinvirran erikoisuus on hyvä kuhakanta. Kuha tulee virtaan kesäkuussa ja vielä elokuussakin voi saada hyviä kuhasaaliita. Joka kesä saadaan myös kookkaita kuhia, jotka kestävät vertailun minkä tahansa hyvän kuhapaikan kanssa. Varkauden Vaaputtajat Ry hoitaa Pirtinvirran Urheilukalastusaluetta, tekee istutukset, järjestää luvanmyynnin ja valvoo alueen kalastusta. <br /><br />

                            Puistoalueella noin 50 m leveällä virran osuudella on vain heittokalastus sallittu, paitsi 1.11.-31.3. välisenä aikana, jolloin alueella saa käyttää soutuvenettä. Tämän alueen eteläpuolelta alkaa alue, jossa myös soutuveneellä kalastus on sallittu. Edelleen etelään päin mentäessä Rautatiesillan jälkeen alue laajenee myös moottoriuistelualueeksi (katso alueen kartta). Soutuveneitä on vuokrattavissa (ABC Varkaus). Pirtinvirralla ei ole syysrauhoitusta.<br /><br />

                        </p>
                    </div>
                    <div>
                        <div className="facebook-container">
                            <h1 className="otsikko-facebook">Kalastusistutuksista lisätietoa Facebook-ryhmästämme!</h1>
                            <a href="https://www.facebook.com/groups/607013516119681" target="_blank" rel="noopener noreferrer" className="Button-teemat"><div className="button-icon"><FontAwesomeIcon icon="facebook" /></div><p>Facebook</p></a>
                        </div>
                        <a href={PUBLIC_IMG_URL + "/Pirtinvirta_urheilukalastusalue2016.pdf"} rel="noopener noreferrer" target="_blank"><img className="img-kartta" src={PUBLIC_IMG_URL + "/kartta_pirtinvirta.png"} alt="Pirtinvirta" /></a>
                        <p><em>Klikkaa karttaa nähdäksesi se isompana.</em></p>
                    </div>
                </div>
            </div>
        );
    }
}